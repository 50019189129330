<template lang="html">
  <div class="vx-row">
    <div class="vx-col w-full lg:w-3/5 mb-base">
      <vx-card :title="$t('transaction.voucher.informUse')">
        <div id="content" class="vs-con-loading__container">
          <div class="mt-5">
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-button
                  class="mt-5 mb-5"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="
                    activePrompt = true;
                    addVoucherNumber = null;
                  "
                  >{{ $t("transaction.voucher.addVoucher") }}</vs-button
                >
              </div>
            </div>

            <vs-table :data="vouchersToUse">
              <template slot="thead">
                <vs-th>ID</vs-th>
                <vs-th>{{ $t("transaction.voucher.voucherNumber") }}</vs-th>
                <vs-th>{{ $t("transaction.value") }}</vs-th>
                <vs-th>{{ $t("transaction.voucher.validThru") }}</vs-th>
                <vs-th>{{ $t("actions.title") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(voucher, index) in vouchersToUse" :key="index">
                  <vs-td>
                    {{ voucher.id }}
                  </vs-td>
                  <vs-td>
                    {{ voucher.number }} <br />
                    <span class="text-danger text-sm">{{ voucher.error }}</span>
                  </vs-td>
                  <vs-td>
                    {{ voucher.value.toFixed(2) }}
                  </vs-td>
                  <vs-td>
                    {{ getHumanDate(voucher.expirationDate) }}
                  </vs-td>
                  <vs-td>
                    <feather-icon
                      icon="XCircleIcon"
                      svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                      @click="removeVoucher(voucher)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="flex-end"
                vs-w="3"
              >
                <vs-button
                  :disabled="!vouchersToUse.length"
                  class="mt-5 mb-8"
                  @click="submitUseVouchers"
                  >{{ $t("transaction.voucher.useVoucher") }}</vs-button
                >
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full lg:w-2/5 mb-base">
      <vx-card :title="$t('transaction.voucher.reverseVoucher')">
        <div class="mt-5">
          <vs-alert
            class="mb-6"
            color="primary"
            icon="new_releases"
            active="true"
          >
            <p>
              {{ $t("transaction.voucher.reverseMsg") }}
            </p>
          </vs-alert>

          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
              <span>{{ $t("transaction.voucher.voucherNumber") }}</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <vx-input-group>
                <vs-input
                  v-on:keyup.enter="reverseVoucher"
                  v-model="undoVoucherNumber"
                  v-validate="'required'"
                  name="voucherNumber"
                  :data-vv-as="$t('transaction.voucher.voucherNumber')"
                />
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button
                      @click="getVoucherData"
                      :disabled="!validateForm || loadingVoucher"
                      >{{ $t("transaction.voucher.getData") }}</vs-button
                    >
                  </div>
                </template>
              </vx-input-group>
              <span
                  class="text-danger text-sm"
                  v-show="errors.has('voucherNumber')"
                  >{{ errors.first("voucherNumber") }}</span
                >
              
            </div>
            
          </div>

          <div class="vx-col mb-6">
            <div v-for="(data, k) in voucherData" :key="k">
              <div class="voucher-data">
                <small><strong><span>{{ data.label }}</span></strong></small>
                <small>: {{ data.value }}</small>
              </div>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button
                class="mr-3 mb-2"
                @click="openReverseConfirm"
                :disabled="!validateForm || !voucherData.length"
                >{{ $t("transaction.voucher.reverse") }}</vs-button
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <vs-prompt
      class="add-voucher-dialog"
      :title="$t('transaction.voucher.addVoucher')"
      :buttonAccept="'false'"
      :buttonCancel="'false'"
      :type="'alert'"
      @cancel="val = ''"
      :active.sync="activePrompt"
    >
      <div>
        <div>
          <span>{{ $t("transaction.voucher.typeVoucherNumber") }}</span>
          <vs-input
            v-on:keyup.enter="addVoucherToList"
            v-model="addVoucherNumber"
            class="mt-3 w-full"
            name="addVoucherNumber"
            :data-vv-as="$t('transaction.voucher.voucherNumber')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('addVoucherNumber')"
            >{{ errors.first("addVoucherNumber") }}</span
          >
        </div>

        <div class="flex flex-wrap p-2" slot="footer">
          <vs-button
            id="button-with-loading"
            class="ml-auto mt-2 vs-con-loading__container"
            @click.native="addVoucherToList"
            >{{ $t("actions.save") }}</vs-button
          >
          <vs-button
            type="flat"
            color="dark"
            class="ml-4 mt-2"
            @click.native="activePrompt = false"
            >{{ $t("actions.cancel") }}</vs-button
          >
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import i18n from "@/i18n/i18n";
import moment from "moment";

export default {
  data: () => ({
    activePrompt: false,
    machine: {},
    data_local: {},
    addVoucherNumber: null,
    undoVoucherNumber: null,
    vouchersToUse: [],
    activeReverseConfirm: false,
    voucherData: [],
    loadingVoucher: false
  }),
  methods: {
    getVoucherData() {
      this.loadingVoucher = true;
      this.voucherData = [];
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.dispatch('httpRequest', {method: 'get', url:'ticket/' + this.undoVoucherNumber }).then((data) => {
            this.loadingVoucher = false;
          
            if (data) {
              if (data.error) {
                this.$vs.notify({
                  title: i18n.t("dialog.error"),
                  text: data.error,
                  color: "danger"
                });
                return;
              }
              
              //this.voucherData.push({ label: 'ID', value: data.id });
              this.voucherData.push({ label: i18n.t('transaction.value'), value: data.value });
              this.voucherData.push({ label: i18n.t('transaction.voucher.validThru'), value: moment(data.expirationDate).format('DD/MM/YYYY') });
              this.voucherData.push({ label: i18n.t('sensors.machine'), value: (data.machine ? data.machine.serial_number : '') });
              this.voucherData.push({ label: i18n.t('common.description'), value: (data.machine ? data.machine.description : '') });
            }
          }).catch(error => {
            this.loadingVoucher = false;
            this.$vs.notify({
              title: i18n.t('dialog.error'),
              text: error.message,
              color: 'danger'
            })
          });
        } else {
          this.loadingVoucher = false;
        }
      });
      
    },
    openReverseConfirm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: i18n.t("dialog.confirm"),
            acceptText: i18n.t('dialog.accept'),
            cancelText: i18n.t('dialog.cancel'),
            text: `${i18n.t('transaction.voucher.reverseVoucher')} ${this.undoVoucherNumber}?`,
            accept: this.reverseVoucher
          });
        }
      });
    },
    reverseVoucher() {
      this.$store
        .dispatch("httpRequest", {
          method: "post",
          url: "ticket/undoBurn",
          data: { number: this.undoVoucherNumber }
        })
        .then(data => {
          if (data.error) {
            this.$vs.notify({
              title: i18n.t("dialog.error"),
              text: data.error,
              color: "danger"
            });
          } else {
            this.$vs.notify({
              title: i18n.t("dialog.success"),
              text: i18n.t(
                "transaction.voucher.messages.success.voucherReverse"
              )
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    removeVoucher(voucher) {
      this.vouchersToUse = this.vouchersToUse.filter(
        item => item.id !== voucher.id
      );
    },
    submitUseVouchers() {
      let self = this;
      this.$store
        .dispatch("httpRequest", {
          method: "post",
          url: "ticket/burn",
          data: { numbers: this.vouchersToUse.map(x => x.number) }
        })
        .then(data => {
          if (data.error) {
            this.$vs.notify({
              title: i18n.t("dialog.error"),
              text: data.error,
              color: "danger"
            });
          } else {
            let voucherUsedCount = 0;
            data.responses.forEach(res => {
              const index = this.vouchersToUse.findIndex(
                voucher => voucher.number == voucher.number
              );
              if (res.success) {
                if (index !== -1) {
                  self.vouchersToUse.splice(index, 1);
                  voucherUsedCount++;
                }
              } else {
                let voucher = self.vouchersToUse[index];
                voucher.error = res.error;
                self.vouchersToUse.splice(index, 1);
                self.vouchersToUse.push(voucher);
              }
            });

            if (!voucherUsedCount) {
              this.$vs.notify({
                color: "danger",
                title: i18n.t("dialog.error"),
                text: i18n.t("transaction.voucher.messages.error.verify")
              });
            } else {
              this.$vs.notify({
                title: i18n.t("dialog.success"),
                text: `${i18n.t(
                  "transaction.voucher.messages.success.voucherUsed"
                )} (${voucherUsedCount})`
              });
            }
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    addVoucherToList() {
      let self = this;
      if (!this.addVoucherNumber) {
        this.$vs.notify({
          title: i18n.t("dialog.error"),
          text: i18n.t("transaction.voucher.typeVoucherNumber"),
          color: "danger"
        });
        return;
      }

      const voucherExists = self.vouchersToUse.filter(
        v => v.number == this.addVoucherNumber
      ).length;
      if (voucherExists) {
        this.$vs.notify({
          title: i18n.t("dialog.error"),
          text: i18n.t(
            "transaction.voucher.messages.error.voucherAlreadyAdded"
          ),
          color: "danger"
        });
        return;
      }

      this.$store
        .dispatch("httpRequest", {
          method: "get",
          url: `/ticket/${this.addVoucherNumber}`
        })
        .then(data => {
          if (data.error) {
            this.$vs.notify({
              title: i18n.t("dialog.error"),
              text: data.error,
              color: "danger"
            });
          } else {
            self.vouchersToUse.push(data);
            self.activePrompt = false;
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        })
        .finally(() => {});
    },
    getHumanDate: function(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    }
  },
  mounted() {
    
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  }
};
</script>
<style lang="scss">
.add-voucher-dialog .vs-dialog footer {
  display: none !important;
}
.not-data-table {
  display: none !important;
}
.voucher-data {
  display: grid;
  grid-template-columns: 100px 100%;
}
</style>
